export default Object.freeze({
  //구글 광고 설정
  TITLE: 'NHN COMMERCE와 함께',
  SUB_TITLE1: '구글과 내 상점을 연결하고',
  SUB_TITLE2: '새로운 판매 루트를 열어보세요.',
  DESC1: '쇼핑몰의 상품을 구글에 무료로 노출할 수 있습니다.',
  DESC2: '상품을 구글과 자동으로 연동하고 손쉽게 수정 및 관리할 수 있습니다.',
  DESC3: '구글 광고를 통해 더 많은 고객들에게 도달할 수 있습니다.',
  ALL_AGREE: '구글 광고 서비스의 모든 약관을 확인하고, 모두 동의합니다.',
  MERCHANT_AGREE: '구글 머천트센터 서비스 이용약관 동의',
  ADVERTISING_AGREE: '구글 광고 프로그램 서비스 이용약관 동의',
  VIEW: '내용 보기',
  START: '시작하기',
  SETTING: '설정하기',
  SELLER_INFO: '판매자 정보 확인',
  ALERT_ADD_CODE: '쇼핑몰에 소유권 인증코드와 광고 추적코드가 \n' + '추가 되었습니다.',
  ALERT_ADD_CODE_FAIL: '쇼핑몰에 소유권 인증코드와 광고 추적코드 추가를 실패했습니다.\n다시 진행 하시겠습니까?',
  PLEASE_ALL_AGREE: '이용약관을 모두 동의 하시기 바랍니다.',
  CONFIRM_START_AD:
    '광고 진행 및 성과 측정을 위해 쇼핑몰에 소유권\n인증코드와 광고 추적코드가 추가 됩니다\n광고를 진행하시겠습니까?',
  NOTICE1: '구글 머천트 센터에 상품 업로드를 위해서 아래 항목을 모두 설정해야 합니다.',
  NOTICE2: '설정이 모두 완료되면 구글 머천트 센터에 승인된 제품이 업로드 됩니다.',
  SMS_TITLE: '문자 인증',
  PHONE_NUM: '휴대폰번호',
  PHONE_PLACEHOLDER: "'-'를 제외한 번호만 입력해주세요.",
  REQUEST_AUTH_CODE: '인증번호 요청',
  INPUT_AUTH_CODE: '인증번호 입력',
  INPUT_AUTH_CODE_6: '6자리 인증번호 입력',
  CONFIRM: '인증완료',
  AUTH_NOTICE1: '입력한 휴대폰번호로 인증번호가 발송됩니다. 휴대폰번호를 정확하게 입력해주세요.',
  AUTH_NOTICE2: '인증번호가 15분 이내로 도착하지 않는 경우 취소를 누른 후 다시 시도해주세요.',
  AUTH_TIME_OUT: '인증시간이 초과되었습니다.\n인증번호를 다시 한번 요청해 주세요.',
  AUTH_RETRY_OUT: '요청횟수를 초과 하였습니다.\n4시간 이후 다시 시도해주세요.',
  AUTH_CODE: '인증번호를 발송했습니다. 인증번호가 오지 않으면 입력한 휴대폰 정보가 맞는지 확인해 주세요.',
  INPUT_PHONE_NUM: '휴대폰번호를 입력해 주세요.',
  INPUT_CODE: '인증번호를 입력해 주세요.',
  FAIL_AUTH: '인증에 실패하였습니다.\n다시 시도해 주세요.',
  PRODUCT_TOOLTIP:
    'Google Merchant Center에 연동 가능한 상품이 1개 이상 등록되어 있어야 합니다.<br>Google Merchant Center에 연동 가능을 위해서는 다음 조건을 모두 만족해야 합니다.<br>- 판매상태 : ‘판매중’<br>- 전시상태 : ‘전시함’<br>- 마케팅 채널 노출 설정 ‘노출함’<br>- 판매가 : 0원보다 큰 금액',
  MARKETING_TOOLTIP:
    '‘마케팅> 마케팅 설정> 마케팅 상품 노출’ 에 마케팅 노출 설정이 구글 광고가 선택된 상태에서 ‘노출함’ 으로 설정된 상품이1개 이상 설정되어 있어야 합니다.',
  MARKETING_LABEL: '마케팅 채널 노출 설정',
  EMAIL_LABEL: '대표이메일 입력',
  REGISTRATION_NUM_LABEL: '사업자등록번호 입력',
  MALL_LABEL: '쇼핑몰명 입력',
  DOMAIN_LABEL: '대표도메인 연결 (보안서버 사용)',
  PG_LABEL: '전자결제 이용설정 (일반결제)',
  PRODUCT_LABEL: '상품 등록',
  BANNER_DESC: 'NHN COMMERCE와 함께 구글과 내 상점을 연결하고 새로운 판매 루트를 열어보세요.',
  REPRESENTATIVE_PHONE_NUM: '대표전화번호 입력',
  BUSINESS_ADDRESS: '사업장 주소 입력',
  USER_CONFIG: '사용자 설정',
  USER_MANAGE: '사용자 관리',
  ADS_INSERT: 'Ads 접속',
  LINK_MERCHANT_CENTER: 'Merchant Center 접속',
  CHECK_MERCHANT_CONFIG: '판매자 설정 확인하기',
  MERCHANT_CENTER_USER_MANAGE: 'Merchant Center 사용자 관리',
  MERCHANT_CENTER_USER_MANAGE_THUMB01: '구글 계정에 로그인 후 Merchant Center 계정 사용자를 추가할 수 있습니다.',
  MERCHANT_CENTER_USER_MANAGE_THUMB02: `사용자로 추가될 경우, Merchant Center에 직접 접근할 수 있습니다.`,
  ADS_USER_MANAGE: 'Ads 사용자 관리​',
  ADS_USER_MANAGE_THUMB01: '구글 계정에 로그인 후 발송된 초대 메일 수락을 통해 Ads 계정 사용자를 추가할 수 있습니다.',
  ADS_USER_MANAGE_THUMB02: `사용자로 추가될 경우, Ads에 직접 접근할 수 있습니다`,
  SHORTCUT: '바로가기',
  ADD: '추가',
  USER_ACCOUNT: '사용자 계정',
  RIGHT: '권한',
  NO_USER: '등록된 사용자가 없습니다.',
  ADS_ACCOUNT_INFO: 'Ads 계정 기본 정보',
  ACCOUNT_ID: '계정 ID',
  REGISTER_METHOD: '결제 수단 등록',
  REGISTER: '등록하기',
  CANT_FOUND_CAMPAIGN: '요청하신 쇼핑몰의 캠페인을 찾을 수 없습니다.',
  NO_VAL_CAMPAIGN: '요청하신 쇼핑몰이 인증이 안되었습니다.',
  PAYMENT_METHOD_REGIST_DESC:
    '구글 광고는 NHN커머스가 아닌 구글 광고 계정 을 통해 청구됩니다. 구글에 상품을 홍보하려면 결제 수단을 등록하세요.',
  PLEASE_REGIST_MERCHANT_USERS: 'Merchant Center 사용자 등록 이후 결제 수단을 등록해 주세요.',
  PLEASE_ADS_USERS: 'Ads  사용자 등록 이후 결제 수단을 등록해 주세요.',
  PAYMENT_METHOD_REGIST: '결제 수단 등록',
  PAYMENT_SETUP_STATUS: '결제 설정 상태 ',
  UNSPECIFIED: '지정되지 않았습니다.',
  UNKNOWN: '반환 값에만 상용됩니다. 이 버전에서 알 수 없는 값을 나타냅니다.',
  PENDING: '결제 설정이 승인 대기 중입니다.',
  APPROVED_HELD:
    '결제 설정이 승인되었지만 해당하는 첫번째 예산은 승인되지 않았습니다. 이는 월별 인보이스용으로 구성된 결제 설정에서만 발생할 수 있습니다.',
  APPROVED: '결제 설정이 승인되었습니다.',
  CANCELLED: '승인 전에 사용자가 결제 설정을 취소했습니다.',
  REGIST_MERCHANT_CENTER: 'Merchant Center 사용자 등록이 완료되었습니다.',
  INVITE_MAIL_ADMIT: 'google 계정으로 발송된 초대 메일을 수락해 주세요. 초대 수락 시 Ads 사용자 추가가 완료됩니다.',
});
