// ----- 검색 조건 옵션 ----
import { CheckBoxGroupOption, OptionData } from '@/helpers/type';

const periodTypeOptions: OptionData<'MONTH' | 'PERIOD'>[] = [
  { name: 'STATISTIC.SALES.PERIOD', value: 'PERIOD' },
  { name: 'STATISTIC.SALES.MONTHLY', value: 'MONTH' },
];

const platformTypeOptions: CheckBoxGroupOption<'ALL' | 'PC_WEB' | 'MOBILE_WEB'> = {
  name: 'platformTypes',
  hasAll: true,
  noBrackets: true,
  nonI18nConvert: false,
  data: [
    {
      id: 'all',
      value: 'ALL',
      display: 'ALL_CHECK',
    },
    {
      id: 'pc',
      value: 'PC_WEB',
      display: 'PC_WEB',
    },
    {
      id: 'mobileWeb',
      value: 'MOBILE_WEB',
      display: 'MOBILE_WEB',
    },
    // TODO: 샵바이 프로에서 모바일 앱 기능 제공 시점에 노출
    // {
    //   id: 'mobileApp',
    //   value: 'MOBILE_APP',
    //   display: 'MOBILE_APP',
    // },
  ],
};

const genderOptions: OptionData<'' | 'UNKNOWN' | 'MALE' | 'FEMALE'>[] = [
  { name: 'ALL', value: '' },
  { name: 'STATISTIC.SALES.UNKNOWN', value: 'UNKNOWN' },
  { name: 'GANDER_MALE', value: 'MALE' },
  { name: 'GANDER_FEMALE', value: 'FEMALE' },
];

const memberTypeOptions: OptionData<'' | 'MALL' | 'PAYCO,NAVER,KAKAO,FACEBOOK'>[] = [
  { name: 'ALL', value: '' },
  { name: 'MEMBER.LIST.BASIC_MEMBER', value: 'MALL' },
  { name: 'MEMBER.LIST.SIMPLE_LOGIN', value: 'PAYCO,NAVER,KAKAO,FACEBOOK' },
];

const memberJoinTypeOptions: OptionData<'' | 'MEMBER' | 'NON_MEMBER'>[] = [
  {
    value: '',
    name: 'STATISTIC.SALES.MEMBER_JOIN_TYPE',
  },
  {
    value: 'MEMBER',
    name: 'STATISTIC.SALES.MEMBER',
  },
  {
    value: 'NON_MEMBER',
    name: 'STATISTIC.SALES.NON_MEMBER',
  },
];

// 그랜드 오픈 이후
// const openIdOptions: OptionData<'PAYCO,NAVER,KAKAO,FACEBOOK' | 'PAYCO' | 'NAVER' | 'KAKAO' | 'FACEBOOK'>[] = [
//   { name: 'ALL', value: 'PAYCO,NAVER,KAKAO,FACEBOOK' },
//   { name: 'PAYCO_KO', value: 'PAYCO' },
//   { name: 'NAVER_KO', value: 'NAVER' },
//   { name: 'KAKAO_KO', value: 'KAKAO' },
//   { name: 'FACEBOOK_KO', value: 'FACEBOOK' },
// ];

// 탭별 search 옵션
export const getPeriodMembersSearchOptions = () => ({
  genderOptions,
  memberTypeOptions,
  periodTypeOptions,
  platformTypeOptions,
  memberJoinTypeOptions,
});
