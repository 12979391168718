











































































































































































































































































































import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import {
  AdminsService,
  AuthorityGroup,
  GetAuthorityGroupsRequest,
  GetDepartmentsService,
  GetJobDutyService,
  PostAdminsTempServiceDepartmentsRequest,
  PostAdminsTempServiceJobDutiesRequest,
} from 'ncp-api-supporter';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { SearchKeywordInputOption } from '@/types';
import SearchKeywordInput from '@/components/common/input/SearchKeywordInput.vue';
import EmailInput from '@/components/common/input/EmailInput.vue';
import { ValidatePasswordState, validatePassword } from '@/utils/validate';
import Captcha from '@/components/common/Captcha.vue';
import { throwPopup } from '@/helpers/popup';
import { errorCode } from '@/utils/common';
import { ERROR_CODE } from '@/const/contents/sms';
import RadioGroup from '@/components/common/RadioGroup.vue';
import IpAddressInputList, { IpWrap } from '@/components/common/IpAddressInputList.vue';
import { adminFormRadioOption } from '@/views/contents/configuration/management/adminChild/AdminForm';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { i18nSecurity } from '@/const/contents/configuration/operateSecurity';

interface Department extends GetDepartmentsService {
  selectKey?: number;
  selectDisplayName?: string;
}
interface JobDuty extends GetJobDutyService {
  selectKey?: number;
  selectDisplayName?: string;
}

interface AdminInfo extends AdminsService {
  departments: Department[];
  jobDuties: JobDuty[];
}

enum ADMIN_ID_EXIST_STATE {
  NONE = 'NONE',
  EXIST = 'EXIST',
  NOT_EXIST = 'NOT_EXIST',
}

enum ADMIN_FORM_MODE {
  EDIT = 'EDIT',
  REGISTER = 'REGISTER',
  EDIT_ME = 'EDIT_ME',
}

enum SMS_AUTH_STATUS {
  NONE = 'NONE',
  ING = 'ING',
  COMPLETE = 'COMPLETE',
}

@Component({
  components: {
    ToolTip,
    IpAddressInputList,
    RadioGroup,
    Captcha,
    TextInput,
    SelectBox,
    SearchKeywordInput,
    EmailInput,
  },
})
export default class AdminForm extends Vue {
  @PropSync('adminInfo') private adminInfoSync!: AdminInfo;
  @PropSync('password') private passwordSync!: string;
  @PropSync('smsAuthKey') private smsAuthKeySync!: string;
  @Prop({ default: 'EDIT' }) private mode: string;
  @PropSync('useIpAccessControl', { default: false })
  private useIpAccessControlSync: boolean;
  @PropSync('ipRowsLength', { default: 0 })
  private ipRowsLengthSync: number;

  @Prop() private readonly meNo: number;
  @Prop() private readonly tempCode: string;
  @Prop() private readonly isCommerceAccountMaster: boolean;
  @Prop({ default: '' })
  private readonly myIp: string;

  private readonly radioOption = adminFormRadioOption;

  private departmentList: Department[] = [];
  private jobDutyList: JobDuty[] = [];
  private authGroupList: AuthorityGroup[] = [];
  private authGroupSelectList: { name: string; value: number }[] = [];

  private permittedIpAddresses: IpWrap[] = [];

  private adminNo = 0;

  private passwordInvalidMessage = '';
  private passwordConfirmInvalidMessage = '';

  private passwordConfirm = '';
  private adminIdExistState = ADMIN_ID_EXIST_STATE.NONE;

  private smsAuthStatus = SMS_AUTH_STATUS.NONE;
  private smsAuthStatusDescription = '';
  private remainTime = 0;
  private remainTimeTimer = null;

  private isAllowsMarketingDirection = false;
  private originAuthGroupNo = 0;

  get authGroupNo(): number {
    return this.isMaster() ? 0 : this.adminInfoSync.authorityGroup.no;
  }
  set authGroupNo(no: number) {
    this.adminInfoSync.authorityGroup.no = no;
  }

  get displayOtpColumn() {
    return (this.isEditMode() && this.meNo === this.adminNo) || this.isEditMeMode() ? true : false;
  }

  private get canConfigureAccessControl(): boolean {
    return this.mode === ADMIN_FORM_MODE.EDIT;
  }

  private isEditMode(): boolean {
    return this.mode === ADMIN_FORM_MODE.EDIT;
  }
  private isRegisterMode(): boolean {
    return this.mode === ADMIN_FORM_MODE.REGISTER;
  }
  private isEditMeMode(): boolean {
    return this.mode === ADMIN_FORM_MODE.EDIT_ME;
  }

  private isMaster(): boolean {
    return this.adminInfoSync.adminRole === 'MASTER';
  }

  private getMoveAuthGroupUrl(authGroupNo?: number): string {
    return this.isMaster() || !authGroupNo
      ? '/pro/configuration/management/authority-group'
      : `/pro/configuration/management/authority-group/edit?groupNo=${authGroupNo}`;
  }

  private isSmsAuthNone(): boolean {
    return this.smsAuthStatus === SMS_AUTH_STATUS.NONE;
  }
  private isSmsAuthIng(): boolean {
    return this.smsAuthStatus === SMS_AUTH_STATUS.ING;
  }
  private isSmsAuthComplete(): boolean {
    return this.smsAuthStatus === SMS_AUTH_STATUS.COMPLETE;
  }

  private resetSmsAuthStatusDescription(): void {
    this.smsAuthStatusDescription = '';
  }

  private getRemainTime(): string {
    const min = Math.floor(this.remainTime / 60);
    const sec = Math.floor(this.remainTime % 60);

    return (min < 10 ? '0' + min : min) + ':' + (sec < 10 ? '0' + sec : sec);
  }

  private getSearchKeywordInputOption(row: string): SearchKeywordInputOption {
    let placeholder = '';
    let maxLength = 20;
    switch (row) {
      case 'department':
        placeholder = this.$t('CONFIGURATION.ADMIN.DEPARTMENT_PLACEHOLDER') as string;
        break;
      case 'jobPosition':
        placeholder = this.$t('CONFIGURATION.ADMIN.JOB_POSITION_PLACEHOLDER') as string;
        break;
      case 'jobDuty':
        placeholder = this.$t('CONFIGURATION.ADMIN.JOB_DUTY_PLACEHOLDER') as string;
        maxLength = 10;
        break;
    }

    return {
      placeholder: placeholder,
      useCount: true,
      useHighlight: true,
      maxLength: maxLength,
    };
  }

  @Watch('adminInfoSync')
  private initSmsAuthStatus() {
    if (
      !this.isRegisterMode() &&
      this.adminInfoSync.contact.mobileNo &&
      this.adminInfoSync.contact.mobileNo.length > 0
    ) {
      this.smsAuthStatus = SMS_AUTH_STATUS.COMPLETE;
    } else {
      this.smsAuthStatus = SMS_AUTH_STATUS.NONE;
    }
  }

  async created() {
    this.adminNo = Number(this.$route.query.adminNo);

    if (!this.adminInfoSync.departments) {
      this.adminInfoSync.departments = [];
    }
    if (!this.adminInfoSync.jobDuties) {
      this.adminInfoSync.jobDuties = [];
    }

    // 부서, 직급, 그룹, 운영자 정보 가져오기
    if (this.isEditMode() || this.isEditMeMode()) {
      await Promise.all([this.fetchDepartments(), this.fetchJobDuties()]);
      if (this.isEditMode()) {
        this.getAuthorityGroups();
      }
    } else {
      this.getDepartments();
      this.getJobDuties();
    }
    if (this.isMaster()) {
      this.authGroupSelectList = [{ name: this.$t('ALL') as string, value: 0 }];
    }

    this.originAuthGroupNo = this.adminInfoSync.authorityGroup.no;
    this.initSmsAuthStatus();
  }

  /**
   * get departments list
   */
  private getDepartments(): Department[] {
    this.departmentList = this.adminInfoSync.departments.map(item => ({
      selectKey: item.departmentNo,
      selectDisplayName: item.departmentName,
      ...item,
    }));
    return this.departmentList;
  }

  private fetchDepartments(): Promise<void> {
    return this.$api.getDepartments().then((response): void => {
      if (response && response.status === 200 && response.data) {
        this.departmentList = response.data;
        this.departmentList.map(item => {
          item.selectKey = item.departmentNo;
          item.selectDisplayName = item.departmentName;
        });
      }
    });
  }

  private async postDepartments(): Promise<boolean> {
    const request: PostAdminsTempServiceDepartmentsRequest = {
      data: {
        tempCode: this.tempCode,
        departmentName: this.adminInfoSync.department.name,
      },
    };

    try {
      const response = await this.$api.postAdminsTempServiceDepartments(request);
      return response?.status === 204;
    } catch (e) {
      return false;
    }
  }

  /**
   * get job duty list
   */
  private getJobDuties(): JobDuty[] {
    this.jobDutyList = this.adminInfoSync.jobDuties.map(item => ({
      selectKey: item.jobDutyNo,
      selectDisplayName: item.jobDutyName,
      ...item,
    }));
    return this.jobDutyList;
  }
  private fetchJobDuties(): Promise<void> {
    return this.$api.getJobDuties().then((response): void => {
      if (response && response.status === 200 && response.data) {
        this.jobDutyList = response.data;
        this.jobDutyList.map(item => {
          item.selectKey = item.jobDutyNo;
          item.selectDisplayName = item.jobDutyName;
        });
      }
    });
  }

  private async postJobDuties(): Promise<boolean> {
    const request: PostAdminsTempServiceJobDutiesRequest = {
      data: {
        tempCode: this.tempCode,
        jobDutyName: this.adminInfoSync.jobDuty.name,
      },
    };

    try {
      const response = await this.$api.postAdminsTempServiceJobDuties(request);
      return response?.status === 204;
    } catch (e) {
      return false;
    }
  }

  /**
   * get auth group (all)
   */
  private getAuthorityGroups(): Promise<void> {
    const request: GetAuthorityGroupsRequest = {
      params: {
        pageSize: 1000,
      },
    };
    return this.$api.getAuthorityGroups(request).then(response => {
      if (response && response.status === 200) {
        this.authGroupList = response.data.contents;

        // select box 처리
        this.authGroupList.map(group => {
          this.authGroupSelectList.push({ name: group.name, value: group.no });
        });
      }
    });
  }

  private getAdminsTempServiceExists(): Promise<void> {
    const request = {
      params: {
        adminId: this.adminInfoSync.adminId,
      },
    };

    return this.$api
      .getAdminsTempServiceExists(request)
      .then(response => {
        this.adminIdExistState = ADMIN_ID_EXIST_STATE.NONE;

        if (response && response.status === 200) {
          if (response.data.exists) {
            this.adminIdExistState = ADMIN_ID_EXIST_STATE.EXIST;
          } else {
            this.adminIdExistState = ADMIN_ID_EXIST_STATE.NOT_EXIST;
          }
        }
      })
      .catch(() => {
        this.adminIdExistState = ADMIN_ID_EXIST_STATE.NONE;
      });
  }

  private postAuthSms(): Promise<boolean> {
    return this.$api
      .postAuthSms({
        data: {
          mobileNo: this.adminInfoSync.contact.mobileNo,
        },
      })
      .then(response => {
        if (response && response.status === 200) {
          this.remainTime = response.data.remainTime;
          return true;
        }

        return false;
      })
      .catch(() => false);
  }

  private showsCaptcha(value: boolean) {
    this.needsCaptcha = value;
  }
  private isCaptchaError(code = ''): boolean {
    const ERROR_CODE = {
      CAPTCHA: 'CP9001',
      EXCEEDING: 'E0300',
    };
    return Object.values(ERROR_CODE).includes(code);
  }
  private setSmsAuthStatusDescription(code: string, message: string) {
    this.smsAuthStatusDescription = code === ERROR_CODE.INVALID_NUMBER ? message : '';
  }
  private alertErrorMessage(code: string) {
    code === ERROR_CODE.EXCEEDED_ATTEMPTS &&
      alert(this.$t('CONFIGURATION.ADMIN.MAXIMUM_AUTHENTICATION_ATTEMPTS_EXCEEDED_10'));
  }
  private getAuthSms(): Promise<boolean> {
    return this.$api
      .getAuthSms({
        params: {
          mobileNo: this.adminInfoSync.contact.mobileNo,
          key: this.smsAuthKeySync,
        },
      })
      .then(response => {
        if (response && response.status === 204) {
          this.showsCaptcha(false);
          return true;
        }
        return false;
      })
      .catch(error => {
        const code = errorCode(error?.data.code);
        this.showsCaptcha(this.isCaptchaError(code));
        this.alertErrorMessage(code);
        this.setSmsAuthStatusDescription(code, error.data.message);
        return false;
      });
  }

  private checkAdminId(): void {
    if (!this.adminInfoSync.adminId) {
      alert(this.$t('CONFIGURATION.ADMIN.ALERT_INSERT_ID'));
      return;
    }
    if (this.adminInfoSync.adminId.length < 5) {
      alert(this.$t('CONFIGURATION.ADMIN.ALERT_INVALID_TEXT'));
      (this.$refs.adminId as TextInput).focus();
      return;
    }

    this.getAdminsTempServiceExists().then(() => {
      if (this.adminIdExistState === ADMIN_ID_EXIST_STATE.NOT_EXIST) {
        alert(this.$t('CONFIGURATION.ADMIN.ALERT_ID_NOT_EXIST'));
      } else if (this.adminIdExistState === ADMIN_ID_EXIST_STATE.EXIST) {
        alert(this.$t('CONFIGURATION.ADMIN.ALERT_ID_EXIST'));
      }
    });
  }

  private validatePassword(): void {
    const result = validatePassword(this.passwordSync);

    switch (result) {
      case ValidatePasswordState.SUCCESS:
        this.passwordInvalidMessage = '';
        break;
      case ValidatePasswordState.LESS_THEN_8:
      case ValidatePasswordState.MORE_THEN_20:
      case ValidatePasswordState.LESS_THEN_10_AT_LEAST_2:
        this.passwordInvalidMessage = this.$t('ALERT_INVALID_PASSWORD_LEAST_2') as string;
        break;
      case ValidatePasswordState.MORE_THEN_10_AT_LEAST_1:
        this.passwordInvalidMessage = this.$t('ALERT_INVALID_PASSWORD_LEAST_1') as string;
        break;
      case ValidatePasswordState.NOT_ALLOW_SPECIAL:
        this.passwordInvalidMessage = this.$t('ALERT_INVALID_PASSWORD_SPECIAL') as string;
        break;
    }
    if (this.passwordConfirm.length >= 1) {
      this.validatePasswordConfirm();
    }
  }

  private validatePasswordConfirm(): void {
    if (this.passwordSync !== this.passwordConfirm) {
      this.passwordConfirmInvalidMessage = this.$t('CONFIGURATION.ADMIN.PASSWORD_CONFIRM_INVALID_MESSAGE') as string;
    } else {
      this.passwordConfirmInvalidMessage = '';
    }
  }

  private handleBlur(value: string) {
    return value;
  }

  public validate(): boolean {
    if (!this.adminInfoSync.adminId) {
      alert(this.$t('CONFIGURATION.ADMIN.ALERT_INSERT_ID'));
      (this.$refs.adminId as TextInput).focus();
      return false;
    }

    if (this.isRegisterMode()) {
      if (this.adminIdExistState === ADMIN_ID_EXIST_STATE.NONE) {
        alert(this.$t('CONFIGURATION.ADMIN.ALERT_CHECK_ID_EXIST'));
        (this.$refs.checkAdminId as HTMLElement).focus();
        return false;
      } else if (this.adminIdExistState === ADMIN_ID_EXIST_STATE.EXIST) {
        alert(this.$t('CONFIGURATION.ADMIN.ALERT_ID_EXIST'));
        (this.$refs.checkAdminId as HTMLElement).focus();
        return false;
      }

      if (!this.passwordSync) {
        alert(this.$t('CONFIGURATION.ADMIN.ALERT_INSERT_PASSWORD'));
        (this.$refs.password as TextInput).focus();
        return false;
      }

      if (this.passwordInvalidMessage) {
        alert(this.passwordInvalidMessage);
        (this.$refs.password as TextInput).focus();
        return false;
      }

      if (this.passwordConfirm === '') {
        alert(this.$t('CONFIGURATION.ADMIN.ALERT_INSERT_PASSWORD_CONFIRM'));
        (this.$refs.passwordConfirm as TextInput).focus();
        return false;
      }

      if (this.passwordSync !== this.passwordConfirm) {
        alert(this.passwordConfirmInvalidMessage);
        (this.$refs.passwordConfirm as TextInput).focus();
        return false;
      }
    } else {
      if (
        !this.adminInfoSync.contact.email ||
        this.adminInfoSync.contact.email.split('@').filter(s => s).length !== 2
      ) {
        alert(this.$t('CONFIGURATION.ADMIN.ALERT_INSERT_EMAIL'));
        (this.$refs.adminEmail as TextInput).focus();
        return false;
      }
    }

    if (!this.adminInfoSync.contact.mobileNo) {
      alert(this.$t('CONFIGURATION.ADMIN.ALERT_INSERT_MOBILE'));
      (this.$refs.mobileNo as TextInput).focus();
      return false;
    }

    if (Number(this.adminInfoSync.contact.mobileNo) < 10) {
      alert(this.$t('CONFIGURATION.ADMIN.ALERT_MIN_LENGTH_OF_MOBILE'));
      (this.$refs.mobileNo as TextInput).focus();
      return false;
    }

    if (!this.isSmsAuthComplete()) {
      alert(this.$t('CONFIGURATION.ADMIN.ALERT_SMS_AUTH_COMPLETE'));
      return;
    }

    const { length: ipAddressesLength } = this.adminInfoSync.permittedIpAddresses;
    const hasIpAddress = ipAddressesLength > 0 && ipAddressesLength === this.ipRowsLengthSync;
    if (this.useIpAccessControlSync && !hasIpAddress) {
      alert(i18nSecurity('ALERT_INSERT_ACCESS_IP'));
      return;
    }

    return true;
  }

  private requestSmsAuth(): void {
    if (this.isSmsAuthComplete()) {
      this.smsAuthStatus = SMS_AUTH_STATUS.NONE;
      this.adminInfoSync.contact.mobileNo = '';
      this.smsAuthKeySync = '';
      return;
    }

    if (!this.adminInfoSync.contact.mobileNo) {
      this.smsAuthStatusDescription = this.$t('CONFIGURATION.ADMIN.ALERT_INSERT_MOBILE') as string;
      return;
    }

    if (this.adminInfoSync.contact.mobileNo.length < 10 || this.adminInfoSync.contact.mobileNo.length > 13) {
      this.smsAuthStatusDescription = this.$t('CONFIGURATION.ADMIN.ALERT_WRONG_MOBILE_NO') as string;
      return;
    }

    this.postAuthSms().then(success => {
      if (success) {
        alert(this.$t('CONFIGURATION.ADMIN.ALERT_SMS_AUTH_REQUEST_SUCCESS'));

        this.smsAuthStatus = SMS_AUTH_STATUS.ING;
        this.resetSmsAuthStatusDescription();
        this.startRemainTimeTimer();
      }
    });
  }

  private startRemainTimeTimer(): void {
    clearTimeout(this.remainTimeTimer);

    this.setRemainTime();
  }
  private setRemainTime(): void {
    if (!this.isSmsAuthIng()) {
      this.remainTime = 0;
      return;
    }

    if (!this.remainTime || this.remainTime < 0) {
      this.smsAuthStatus = SMS_AUTH_STATUS.NONE;
      this.remainTime = 0;
      this.smsAuthStatusDescription = this.$t('CONFIGURATION.ADMIN.SMS_AUTH_OVER_TIME_MESSAGE') as string;
      return;
    }

    this.remainTimeTimer = setTimeout(() => {
      --this.remainTime;

      this.setRemainTime();
    }, 1000);
  }

  @Ref()
  private readonly captcha: Captcha;
  private needsCaptcha = false;
  private async confirmSmsAuth(): Promise<void> {
    if (!this.smsAuthKeySync) {
      alert(this.$t('CONFIGURATION.ADMIN.INSERT_SMS_AUTH_NUMBER_PLACEHOLDER'));
      return;
    }
    try {
      if (this.needsCaptcha) {
        await this.captcha.submitCode(true);
        this.showsCaptcha(false);
        await this.confirmSmsAuth();
        return;
      }

      this.getAuthSms().then(success => {
        if (success) {
          this.smsAuthStatus = SMS_AUTH_STATUS.COMPLETE;
          this.resetSmsAuthStatusDescription();
          this.showsCaptcha(false);
        }
      });
    } catch (error) {
      this.captcha.errorHandler(error, false);
    }
  }

  private changeDepartment(): void {
    const department = this.departmentList.filter(d => d.departmentName === this.adminInfoSync.department.name)[0];

    if (department) {
      this.adminInfoSync.department.no = department.departmentNo;
    } else {
      this.adminInfoSync.department.no = 0;
    }
  }
  private createDepartment() {
    this.changeDepartment();
  }

  private changeJobDuty(): void {
    const jobDuty = this.jobDutyList.filter(j => j.jobDutyName === this.adminInfoSync.jobDuty.name)[0];

    if (jobDuty) {
      this.adminInfoSync.jobDuty.no = jobDuty.jobDutyNo;
    } else {
      this.adminInfoSync.jobDuty.no = 0;
    }
  }
  private createJobDuty(): void {
    this.changeJobDuty();
  }

  private openOtpSettingPopup(): void {
    throwPopup({
      name: 'OtpAuthSetting',
    }).then(response => {
      if (response && response.state === 'confirm') {
        this.adminInfoSync.otp.used = true;
      }
    });
  }

  private handleAdminIdChangedReplace(value: string): string {
    // 아이디 중복확인 상태 변경
    this.adminIdExistState = ADMIN_ID_EXIST_STATE.NONE;

    return value.replace(/^\d.*/, '');
  }

  // NOTE 운영자 수정일 때만 운영자 ip 접속제한

  @Watch('adminInfo.permittedIpAddresses')
  private setPermittedIpAddresses() {
    if (!this.adminInfoSync.permittedIpAddresses?.length) return;
    this.permittedIpAddresses = this.adminInfoSync.permittedIpAddresses.map((ip, idx) => {
      const [address, bandwidth = ''] = ip.split('-');
      const ipWrap: IpWrap = {
        no: idx + 1,
        ip: {
          address,
          bandwidth,
        },
      };

      return ipWrap;
    });
  }

  private changedIpRows(ipRowsLength: number) {
    this.ipRowsLengthSync = ipRowsLength;
  }

  private resetPermittedIpAddresses(useIpAddressControl) {
    this.permittedIpAddresses = [];
    this.adminInfoSync.permittedIpAddresses = [];
    this.ipRowsLengthSync = 0;
    this.changedIpRows(useIpAddressControl ? 1 : 0);
  }

  // AdminIpAccessSetting 과 동일
  private changePermittedIpAddresses() {
    this.adminInfoSync.permittedIpAddresses = this.permittedIpAddresses
      .map(pia => {
        const arr = pia.ip.address.split('.').filter(ia => ia);

        if (arr && arr.length === 4) {
          return `${pia.ip.address}${pia.ip.bandwidth ? `-${pia.ip.bandwidth}` : ''}`;
        }
      })
      .filter(item => item);
  }
}
