export const adminFormRadioOption = {
  ipAccessControl: {
    name: 'ipAccessControl',
    data: [
      {
        id: 'IP_ACCESS_CONTROL_NOT_USE',
        display: 'NOT_USED',
        value: false,
      },
      {
        id: 'IP_ACCESS_CONTROL_USE',
        display: 'USED',
        value: true,
      },
    ],
  },
};
