












import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TuiTreeModule from 'tui-tree';
import 'tui-tree/dist/tui-tree.css';
import {
  TuiTree,
  NestedTreeNode,
  TreeNode,
  TreeOptions,
  SelectTreeEvent,
  BeforeEditNodeEvent,
  BeforeMoveTreeEvent,
  MoveTreeEvent,
  TreeStoreEvent,
  EventKeyword,
  ValidationHook,
} from '@/types/tree';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { CategoryTreeNode } from '@/components/product/categorization/EditForm.vue';

const tree = namespace('tree');
@Component({
  components: { NoticeBox },
})
export default class Tree extends Vue {
  @tree.Action('setTree')
  private readonly initTree: (treeNodes: NestedTreeNode[]) => void;
  @tree.Action('setNode')
  private readonly setNode: (data: { node: TreeNode; path: string[] }) => void;
  @tree.Action('setParentNode')
  private readonly setParentNode: (node: TreeNode) => void;
  @tree.Action('resetStore')
  private readonly resetStore: () => void;
  @tree.Action('publishEvent')
  private readonly publishEvent: (eventKeyword: EventKeyword) => void;
  @tree.Getter('getCurrentUpdatedNode')
  private readonly getCurrentUpdatedNode: TreeNode;
  @tree.Getter('getEvent')
  private readonly getEvent: TreeStoreEvent;

  private readonly defaultOptions: TreeOptions = {
    internalNodeName: 'internalNode',
    leafNodeName: 'leafNode',
    depthLimit: 5,
    defaultSelectNodeIndex: 1,
    defaultOpenChildIndex: 0,
    editable: false,
    draggable: false,
    defaultSelection: false,
    parentDataTrack: false,
  };

  private readonly defaultValidationHook: ValidationHook = {
    removeNode: () => true,
    moveNode: () => true,
    changeNode: () => true,
  };

  @Prop({ required: true }) private readonly treeNodes: NestedTreeNode[];
  @Prop({ required: true }) private readonly internalNode: string;
  @Prop({ required: true }) private readonly leafNode: string;
  @Prop({ required: false, default: null }) private readonly options: TreeOptions;
  @Prop({ required: false, default: null }) private readonly validationHook: ValidationHook;
  private treeOptions: TreeOptions = Object.assign(this.defaultOptions, this.options);
  private treeValidationHook: ValidationHook = Object.assign(this.defaultValidationHook, this.validationHook);
  private tree: TuiTree = null as TuiTree;

  @Watch('getEvent.eventCount', { deep: true })
  subscribeEvent() {
    const eventKeyword = this.getEvent.eventName;
    switch (eventKeyword) {
      case 'SET_TREE': {
        this.init(this.treeNodes);
        break;
      }
      case 'UPDATE_TREE':
        this.initTree(this.treeNodes);
        break;
      case 'RECODE_NODE':
        this.updatePrevSelectNodeData(this.getCurrentUpdatedNode);
        break;
      case 'ADD_NODE':
        this.addTreeNode(false);
        break;
      case 'REMOVE_NODE':
        this.removeTreeNode();
        break;
      case 'OPEN_ALL_NODE':
        this.openAllNode();
        break;
      case 'CLOSE_ALL_NODE':
        this.closeAllNode();
        break;
    }
  }

  public getSelectedNodeId(): string {
    return this.tree.getSelectedNodeId();
  }

  public select(nodeId: string) {
    this.tree.select(nodeId);
  }

  get hasNoChild(): boolean {
    return !this.tree?.model.rootNode._childIds?.length;
  }

  beforeDestroy() {
    this.resetStore();
  }

  private init(treeData) {
    this.setTree(treeData);
    Tree.setEnableFeature(this.tree, this.treeOptions);
    this.bindEvents(this.tree, this.treeOptions);
    if (this.treeOptions.defaultSelection) {
      this.defaultTreeSelect(this.options.defaultSelectNodeIndex, this.options.defaultOpenChildIndex);
    }
  }

  /**
   * @getTreeData TUI 트리는 해시테이블 형태로 트리 노드들의 데이터를 관리한다. 이를 트리(자료구조) 형태로 바꾸어 내보내기 위한 메서드이다.
   * 이 메서드가 무슨 동작을 하는지 의아하다면 this.tree.model.treeHash 를 살펴보자.
   */
  public getTreeData(): NestedTreeNode[] {
    if (!this.tree) throw new Error('tree is undefined');

    const ROOT_NODE_ID = this.tree.getRootNodeId();
    const treeHash = this.tree.model.treeHash;

    /**
     * @hashNodeRecursion 해시테이블을 순회하며, 트리 노드에 자식이 있다면(!celibacy) 재귀형태로 자신을 호출하여 밑바닥까지 탐색한다.
     *
     * 처음에 getNodeData()만을 탐색하여 리턴해주던 이 재귀함수는 점점 변태적으로 변하고 있다.
     * 하지만 한번의 완전탐색으로 getTreeData() 를 완수하기위해서는 이 함수를 확장하는것외에 방법이 생각나지않는다.
     *
     * 변수 및 인자의 용도
     * @index 트리 노드의 인덱스를 맵핑하는 역할을 한다. 특이하게도 1부터 시작하기때문에 1을 가산한다..
     * @depth 트리 노드의 깊이(depth)를 맵핑하는 역할을 한다. 재귀 형태로 하위 노드를 탐색할때마다 1씩 가산한다.
     *
     */
    const hashNodeRecursion = (nodeId, depth = 1) =>
      treeHash[nodeId]._childIds.map((id, i) => {
        const celibacy = !treeHash[id]._childIds.length;
        const index = i + 1; // displayOrder 가 1부터 시작해서 가산해야함.
        if (celibacy) return { ...this.tree.getNodeData(id), index, depth };
        return {
          ...this.tree.getNodeData(id),
          index,
          depth,
          children: hashNodeRecursion(id, depth + 1),
        };
      });

    return hashNodeRecursion(ROOT_NODE_ID);
  }

  private setTree(treeNodes: TreeNode[]): void {
    this.tree = new TuiTreeModule('#tree', {
      data: treeNodes,
      nodeDefaultState: 'closed',
      template: {
        internalNode: this.internalNode,
        leafNode: this.leafNode,
      },
    });
  }

  private static setEnableFeature(tree: TuiTree, treeOptions: TreeOptions): void {
    tree.enableFeature('Selectable', { selectedClassName: 'tui-tree-selected' });
    if (!treeOptions) return;

    if (treeOptions.editable) {
      tree.enableFeature('Editable', { dataKey: 'nodeName' });
    }
    if (treeOptions.draggable) {
      tree.enableFeature('Draggable', { isSortable: true });
    }
  }

  private bindEvents(tree: TuiTree, treeOptions: TreeOptions): void {
    tree.on('beforeSelect', this.beforeSelectTreeNode);
    tree.on('select', this.selectTreeNode);
    if (treeOptions.editable) {
      tree.on('beforeEditNode', this.beforeEditNode);
    }
    if (treeOptions.draggable) {
      tree.on('beforeMove', this.beforeMoveTreeNode);
      tree.on('move', this.moveTreeNode);
    }
  }

  public defaultTreeSelect(nodeIndex = 1, childIndex = 0): void {
    const node = Object.values(this.tree.model.treeHash)[nodeIndex];
    if (node?._id === undefined) return;
    this.tree.select(node._id);
    const parentIndex = childIndex || this.tree.model.rootNode._childIds.indexOf(node._parentId);
    this.tree.open(this.tree.model.rootNode._childIds[parentIndex]);
  }

  /**
   * @param nodeId 의 depth 만큼 재귀를 돌아 자신 부터 최상위 부모 까지의 nodeName 을 배열로 리턴한다.
   * @return ex) 4Depth 의 '블랙박스' 선택 시 ['디지털/가전', '자동차기기' ,'블랙박스/액세서리' ,'블랙박스']
   */
  private getNodePath(nodeId: string): string[] {
    const depth = this.tree.getDepth(nodeId);
    const treeHash = this.tree.model.treeHash;

    const nodeDepthRecursion = (nodeId, depth, result = []) => {
      if (depth <= 0) return result;
      result.unshift(treeHash[nodeId]._data.nodeName);
      return nodeDepthRecursion(treeHash[nodeId]._parentId, depth - 1, result);
    };

    return nodeDepthRecursion(nodeId, depth);
  }

  private beforeSelectTreeNode(event: SelectTreeEvent): boolean {
    const prevNodeData = this.tree.getNodeData(event.prevNodeId);
    const nextNodeData = this.tree.getNodeData(event.nodeId);
    if (prevNodeData !== null) {
      prevNodeData['isLeaf'] = this.tree.isLeaf(event.prevNodeId);
      prevNodeData['nodeId'] = event.prevNodeId;
    }
    nextNodeData['isLeaf'] = this.tree.isLeaf(event.nodeId);
    nextNodeData['nodeId'] = event.nodeId;

    if (!this.treeValidationHook.changeNode(event, { prevNodeData, nextNodeData })) return false;

    if (event.prevNodeId === null) return true;
    if (prevNodeData?.nodeName === undefined || prevNodeData.nodeName) return true;
    alert(this.$t('ALERT_INPUT_EMPTY', { fieldName: this.treeOptions?.customKeywords?.nodeLabel ?? 'nodeName' }));
    return false;
  }

  private async selectTreeNode(event: SelectTreeEvent) {
    const isClickedAddButton = !!event.target?.attributes['data-add-child-node'];
    if (isClickedAddButton) {
      this.$nextTick(() => this.addTreeNode());
      return;
    }

    const node = this.tree.getNodeData(event.nodeId);
    const MAX_CATEGORY_LENGTH = 30;
    if (node.nodeName.length > MAX_CATEGORY_LENGTH) {
      node.nodeName = node.nodeName.slice(0, MAX_CATEGORY_LENGTH);
      this.updatePrevSelectNodeData(node);
    }
    node['isLeaf'] = this.tree.isLeaf(event.nodeId);
    node['nodeId'] = event.nodeId;
    const path = this.getNodePath(event.nodeId);

    await this.publishEvent('ASSEMBLE_NODE');
    await this.setNode({ node, path });
    if (this.defaultOptions.parentDataTrack) {
      const parentNodeId = this.tree.getParentId(event.nodeId);
      const parentNode = this.tree.getNodeData(parentNodeId);
      if (Object.keys(parentNode).length > 0) {
        await this.setParentNode(parentNode);
      } else {
        await this.setParentNode(null);
      }
    }
    this.tree.open(event.nodeId);
  }

  private beforeEditNode(event: BeforeEditNodeEvent): boolean {
    if (!event.value.trim()) {
      alert(this.$t('ALERT_INPUT_EMPTY', { fieldName: this.treeOptions?.customKeywords?.nodeLabel ?? 'nodeName' }));
      return false;
    }

    const parentId = this.tree.getParentId(event.nodeId);
    const parentElement = document.getElementById(parentId);
    if (parentElement) parentElement.classList.remove('tui-tree-selected');
    this.$nextTick(() => this.tree.select(event.nodeId));

    return true;
  }

  private beforeMoveTreeNode(event: BeforeMoveTreeEvent): boolean {
    const newParentNodeData = this.tree.getNodeData(event.newParentId);

    if (!this.treeValidationHook.moveNode(newParentNodeData)) return false;

    const depthOfLesseeNode = this.tree.getDepth(event.newParentId); // 드래그 대상 노드의 깊이
    let depthOfTenantNode = 0; // 드래그 되는 노드의 최대 깊이
    this.tree.each((_, nodeId) => {
      depthOfTenantNode =
        this.tree.getDepth(nodeId) > depthOfTenantNode ? this.tree.getDepth(nodeId) : depthOfTenantNode;
    }, event.nodeId);

    // 설정된 최대 깊이를 초과(isDepthExceed)하는가? 그렇다면 움직일 수 없다.
    const totalDepth = depthOfLesseeNode + depthOfTenantNode;
    const depthLimit = this.treeOptions.depthLimit;
    const isDepthExceed = totalDepth >= depthLimit;

    if (isDepthExceed) {
      alert(this.$t('COMMON.TREE.ALERT_DEPTH_IMPOSSIBLE', { depth: depthLimit }));
      return false;
    }

    return true;
  }

  private moveTreeNode(event: MoveTreeEvent): void {
    this.tree.open(event.newParentId);
  }

  private addTreeNode(onSelectedTree = true): void {
    const ROOT_NODE_ID = 'tui-tree-node-0'; // FIXME: 이게 불변한다는 보장이없는데 ROOT_NODE_ID 를 얻어낼 스마트한 처리 필요
    const selectedNodeId = this.getSelectedNodeId();

    const depthLimitCheck = onSelectedTree && this.tree.getDepth(selectedNodeId) >= this.treeOptions.depthLimit;
    if (depthLimitCheck) throw new Error('permitted depth has been exceeded');

    const addLocation = onSelectedTree ? selectedNodeId : ROOT_NODE_ID;

    this.tree.open(selectedNodeId);
    const newNodeId = this.tree.add(
      { nodeName: this.treeOptions?.customKeywords?.nodeName || 'nodeName', ...this.treeOptions.defaultNodeData },
      addLocation,
    )[0];

    this.tree.select(newNodeId);
    if (this.tree.getDepth(selectedNodeId) < this.tree.getDepth(newNodeId)) {
      document.getElementById(selectedNodeId).classList.add('tui-tree-selected'); // 이때 부여된 클래스는 편집 완료후 beforeEditNode 메서드에서 회수한다.
    }
    this.tree.editNode(this.getSelectedNodeId());
  }

  public removeTreeNode(): void {
    if (!this.treeValidationHook.removeNode()) return;

    const selectedNodeId = this.getSelectedNodeId();
    const isLeafNode = this.tree.isLeaf(selectedNodeId);
    const name = this.treeOptions.leafNodeName;

    if (!isLeafNode) {
      const childIds = this.tree.getChildIds(selectedNodeId);
      const mappingProductCnt = childIds.filter(id => {
        const { productCount } = this.tree.getNodeData(id);
        return productCount !== undefined && productCount !== 0;
      });

      if (mappingProductCnt.length > 0) {
        alert(this.$t('COMMON.TREE.ALERT_REMOVE_FAIL', { name }));
        return;
      }
    }

    if (!confirm(this.$t('COMMON.TREE.CONFIRM_REMOVE', { name }).toString())) return;

    this.tree.remove(selectedNodeId);

    if (this.hasNoChild) this.resetStore();
    this.defaultTreeSelect(this.options.defaultSelectNodeIndex, this.options.defaultOpenChildIndex);
  }

  private updatePrevSelectNodeData(nodeData): void {
    this.tree.setNodeData(nodeData.nodeId, nodeData);
  }

  private openAllNode(): void {
    this.tree.eachAll((_, nodeId) => this.tree.open(nodeId));
  }

  private closeAllNode(): void {
    this.tree.eachAll((_, nodeId) => this.tree.close(nodeId));
  }

  public updateSubTree({ node, updateData }: { node: CategoryTreeNode; updateData: object }) {
    const getAllIds = nodeId => {
      const childIds = this.tree.getChildIds(nodeId);
      childIds.forEach(id => getAllIds(id).forEach(id => childIds.push(id))); // 재귀해서 하위 모든 트리 아이디 얻어냄
      return childIds;
    };
    const allIds = getAllIds(node.nodeId);

    allIds.forEach(id => {
      const data = this.tree.getNodeData(id);
      Object.assign(data, updateData);
      this.tree.setNodeData(id, data);
    });
  }
}
