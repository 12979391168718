import DefaultLayout from '@/views/DefaultLayout.vue';
import { RouteConfig } from 'vue-router';
import AppListIframe from '@/views/contents/app/basic/AppListIframe.vue';

export default (): RouteConfig => {
  return {
    path: '/app',
    name: 'App',
    component: DefaultLayout,
    children: [
      {
        path: 'basic/list',
        name: 'AppList',
        component: AppListIframe,
      },
    ],
  };
};
