import { i18n } from '@/main';
import { api } from '@/api';
import type { AppsAppNoInstalledMalls } from 'ncp-api-supporter';
import { throwPopup } from '@/helpers/popup';

export const getInitFavoriteMenu = () => ({
  displayable: true,
  name: i18n.t('FAVORITES').toString(),
  menus: [],
  order: 0,
  uri: 'favorite',
});

export const executeApp = async (appNo: number, { mallNo, popupInfo, installedDateTime, expireDateTime, paymentType }: AppsAppNoInstalledMalls): Promise<void> => {
  const expiredInAppPurchase =
    paymentType === 'IN_APP_PURCHASE' &&
    expireDateTime &&
    installedDateTime !== expireDateTime &&
    new Date(expireDateTime) < new Date();

  const {
    data: { code, appUrl },
  } = await api.postWorkspaceAdminAuthTokenServerApiCode({
    data: { appNo: appNo.toString(), mallNo },
  });

  if (expiredInAppPurchase) {
    // 얼럿 호출 후 계속 동작함
    // 권한이 없는 경우는 노출할 필요가 없어서 post 호출 후 성공 시에만 얼럿 노출
    alert(window.$t('APPSERVICE_APP_LIST_ALERT_APP_EXPIRED'));
  }

  if (!appUrl) {
    alert(window.$t('APPSERVICE_APP_LIST_ALERT_NOT_VALID_APP_URL'));

    return;
  }

  const url = `${appUrl}?code=${code}`;

  if (popupInfo) {
    const x = (window.screenX || window.screenLeft || 0) + (screen.width - popupInfo.width) / 2;
    const y = (window.screenY || window.screenTop || 0) + (screen.height - popupInfo.height) / 2;

    window.open(
      url,
      '_blank',
      `toolbar=no, channelmode=no, location=no, directories=no, menubar=no, resizable=no, top=${y}, left=${x}, width=${popupInfo.width}, height=${popupInfo.height}`,
    );
  } else {
    window.open(url, '_blank');
  }

}

export const fetchApp = async (appNo: number)  =>{
  const { data: appInfo } = await api.getAppsAppNoInstalledMalls({ pathParams: { appNo } });

  if (appInfo.length > 1) {
    throwPopup({
      name: 'SelectMallModal',
      data: {
        appInstalledMalls: appInfo,
        selectedAppNo: appNo,
      },
    });
  } else {
    executeApp(appNo, appInfo?.at(0) ?? null);
  }
}