


























































































































import { Vue, Component, Watch, Prop, PropSync } from 'vue-property-decorator';
import { GetMemberGradesRequest, Grade, NCPResponse } from 'ncp-api-supporter';
import { OptionData } from '@/helpers/type';
import { addMonth, addYear, firstDayOfMonth, getDateDiff, getFirstAndEndDay } from '@/utils/dateFormat';
import { getPeriodSalesSearchOptions as getSearchOptions } from '@/views/contents/statistics/sales/searchOptions';
import { aMonthAgo, defaultDateInfo, getDefaultParams, today } from '@/views/contents/statistics/sales/periodSales';
import MallSelect from '@/components/common/input/MallSelect.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import CheckBoxGroup from '@/components/common/CheckboxGroup.vue';
import { QueryString, StatisticSynthesisPeriod } from '@/types';
import { PERIOD_TYPE } from '@/const/contents/statistics';
import DatePeriodPicker from '@/components/common/DatePeriodPicker.vue';

@Component({
  components: {
    DatePeriodPicker,
    MallSelect,
    SelectBox,
    DateRangePicker,
    RadioGroup,
    SearchButton,
    CheckBoxGroup,
  },
})
export default class PeriodSalesForm extends Vue {
  @PropSync('period')
  private periodSync: null | StatisticSynthesisPeriod;
  @Prop({ required: true }) private readonly resetStatisticSynthesis: () => void;

  private params = getDefaultParams();

  private searchOptions = getSearchOptions();

  private periodType = PERIOD_TYPE.MONTH;
  private memberType = 'ALL';
  private selectedOpenId = 'PAYCO,NAVER,KAKAO,FACEBOOK';
  private selectYear = null;
  private selectMonth = null;

  @Watch('memberType')
  @Watch('selectedOpenId')
  private changedMemberType() {
    this.params.memberType = this.isSimpleLogin ? this.selectedOpenId : this.memberType;
    this.selectedOpenId = 'PAYCO,NAVER,KAKAO,FACEBOOK';
  }

  @Watch('periodType')
  private resetDateParams() {
    this.changeSelectDate();
  }

  @Watch('$route.query')
  private resetSearchResults(query: QueryString) {
    if (Object.keys(query).length !== 0) return;
    this.resetStatisticSynthesis();
    this.reset();
  }

  @Watch('params.mallNo')
  private async fetchMemberGrades(): Promise<void> {
    if (!this.params.mallNo) {
      this.setMemberGradeOptions([]);
      return;
    }
    const request: GetMemberGradesRequest = {
      params: {
        mallNo: Number(this.params.mallNo),
        used: true,
      },
    };

    const memberGradeResponse: NCPResponse<Grade[]> = await this.$api.getMemberGrades(request);
    this.setMemberGradeOptions(this.makeGradeOptions(memberGradeResponse));
  }

  private get isSimpleLogin(): boolean {
    return this.memberType === 'SIMPLE_LOGIN';
  }

  private makeGradeOptions(memberGradeResponse) {
    const sortedData = memberGradeResponse.data.sort((gradeX, gradeY) => gradeX.order - gradeY.order);
    return sortedData.map(grade => ({
      value: grade.no,
      name: grade.name,
    }));
  }

  private setMemberGradeOptions(memberGradeOptions: OptionData<number>[]) {
    this.searchOptions.memberGradeOptions = [this.searchOptions.memberGradeOptions[0], ...memberGradeOptions];
    this.setDefaultMemberGradeNo();
  }

  private setDefaultMemberGradeNo() {
    const DEFAULT = 0;
    const memberGradeNoQuery = Number(this.$route.query?.memberGradeNo);

    if (!memberGradeNoQuery) {
      this.params.memberGradeNo = DEFAULT;
    }

    const hadMemberGrade = this.searchOptions.memberGradeOptions.find(
      memberGradeOption => memberGradeOption.value === memberGradeNoQuery,
    );

    this.params.memberGradeNo = hadMemberGrade ? memberGradeNoQuery : DEFAULT;
  }

  private get isMonthPeriodType() {
    return this.periodType === PERIOD_TYPE.MONTH;
  }

  private changeSelectDate() {
    if (this.isMonthPeriodType) {
      const { startYmd, endYmd } = getFirstAndEndDay(this.selectYear, this.selectMonth);
      this.$set(this.params, 'startYmd', startYmd);
      this.$set(this.params, 'endYmd', endYmd);
    } else {
      this.$set(this.params, 'startYmd', aMonthAgo);
      this.$set(this.params, 'endYmd', today);
    }
  }

  private validateMallNoSelected() {
    if (!this.params.mallNo) {
      alert(this.$t('쇼핑몰을 먼저 선택해주세요.'));
    }
  }

  private validationInfoBy = {
    overFiveYears({ comparisonDate, startYmd }, msgType) {
      const beforeFiveYears = addYear(comparisonDate, -5);
      const overFiveYears = getDateDiff(beforeFiveYears, startYmd, 'day') < 0;
      return {
        isOver: overFiveYears,
        date: overFiveYears ? beforeFiveYears : startYmd,
        msg: `STATISTIC.MEMBER.VERIFY_MESSAGE_${msgType}_MAX_RANGE`,
      };
    },
    overThreeMonths({ startYmd, endYmd }, msgType = PERIOD_TYPE.PERIOD) {
      const beforeThreeMonths = addMonth(endYmd, -3);
      const overThreeMonths = getDateDiff(beforeThreeMonths, startYmd, 'day') < 0;
      return {
        isOver: overThreeMonths,
        date: overThreeMonths && beforeThreeMonths,
        msg: `STATISTIC.MEMBER.VERIFY_MESSAGE_${msgType}_MAX_RANGE`,
      };
    },
    [PERIOD_TYPE.PERIOD]: {
      checkRange({ startYmd, endYmd }) {
        const overFiveYears = this.overFiveYears({ comparisonDate: today, startYmd }, PERIOD_TYPE.MONTH);
        return overFiveYears.isOver ? overFiveYears : this.overThreeMonths({ startYmd, endYmd });
      },
      undoChange(beforeThreeMonths) {
        this.$set(this.params, 'endYmd', null);
        this.$set(this.params, 'startYmd', beforeThreeMonths);
        this.$nextTick(() => this.$set(this.params, 'endYmd', addMonth(beforeThreeMonths, 1)));
      },
    },
    [PERIOD_TYPE.MONTH]: {
      checkRange({ startYmd }) {
        return this.overFiveYears({ comparisonDate: firstDayOfMonth(), startYmd }, PERIOD_TYPE.MONTH);
      },
      undoChange(beforeFiveYears) {
        const [year, month] = beforeFiveYears.split('-');
        this.selectYear = year;
        this.selectMonth = month;
        this.$set(this.params, 'startYmd', beforeFiveYears);
      },
    },
  };

  private validateDateRange(): boolean {
    const { checkRange, undoChange } = this.validationInfoBy[this.periodType];

    const { date, msg, isOver } = checkRange.call(this.validationInfoBy, this.params);

    if (isOver) {
      alert(this.$t(msg));
      undoChange.call(this, date);
      return !date;
    }

    if (!this.params?.platformTypes) {
      alert(this.$t(`STATISTIC.MEMBER.VERIFY_MESSAGE_PLATFORM`));
      return false;
    }

    return true;
  }

  private reset() {
    this.params = getDefaultParams();
    this.resetDetailFormValues();
    this.changeSelectDate();
    this.periodSync = null;
    this.$router.replace({ query: null }).catch(() => null);
  }

  // param이 아닌, 폼 컴포넌트 내부에서만 사용하는 form value 리셋
  private resetDetailFormValues() {
    this.periodType = PERIOD_TYPE.MONTH;
    this.memberType = 'ALL';
    this.selectedOpenId = 'PAYCO,NAVER,KAKAO,FACEBOOK';
    this.isMonthPeriodType && this.resetDate();
  }

  private resetDate() {
    const { year, month } = defaultDateInfo();
    this.selectYear = year;
    this.selectMonth = month;
  }
  created() {
    this.resetDate();
    this.changeSelectDate();
  }
}
