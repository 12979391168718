






import { Vue } from 'vue-property-decorator';

export default class Reject080Main extends Vue {}
