






































































import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TreeNode, TreeStoreEvent } from '@/types/tree';
import copy from 'copy-to-clipboard';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { radioDisplayYnOptionGenerator } from '@/utils/generateFieldOptions';
import TextInput from '@/components/common/input/TextInput.vue';

const tree = namespace('tree');

export interface CategoryTreeNode extends TreeNode {
  depth: number;
  displayYn: 'Y' | 'N';
  topImageContent?: string;
  displayCategoryName: string;
  categoryUrl: string;
  displayOrder: number;
  productCount: number;
  parentDisplayCategoryNo: number;
  displayCategoryNo: number;
}

@Component({
  components: {
    TextInput,
    RadioGroup,
  },
})
export default class EditForm extends Vue {
  @tree.Action('recordNode')
  private readonly recordNode: (payload: { node: TreeNode; uniqueKey: string }) => void;
  @tree.Getter('getNode')
  private readonly getNode: TreeNode;
  @tree.Getter('getParentNode')
  private readonly parentNode: TreeNode | null;
  @tree.Getter('getEvent')
  private readonly getEvent: TreeStoreEvent;

  private data: CategoryTreeNode = null as CategoryTreeNode;
  private displayYnOption = radioDisplayYnOptionGenerator();
  private maxNodeNameLength = 30;

  @Ref() displayCategoryName!: TextInput;
  @Ref() radioGroup!: RadioGroup;

  get invisibleParent(): boolean {
    if (this.parentNode === null) return false;
    const { displayYn } = this.parentNode;
    if (displayYn === undefined) return false;
    return displayYn === 'N';
  }

  public validation(): boolean {
    const hasNodeName = !!this.data.nodeName;
    if (!hasNodeName) {
      alert(this.$t('ALERT_INPUT_EMPTY', { fieldName: this.$t('PRODUCT.CATEGORIZATION.NAME').toString() }));
      this.data.nodeName = this.getNode.nodeName
        ? this.getNode.nodeName
        : this.$t('PRODUCT.CATEGORIZATION.NAME').toString();
      this.displayCategoryName.focus();
    }
    return hasNodeName;
  }

  @Watch('getEvent.eventCount', { deep: true })
  subscribeEvent() {
    const eventKeyword = this.getEvent.eventName;
    switch (eventKeyword) {
      case 'SET_NODE':
        this.setData(this.getNode);
        break;
      case 'ASSEMBLE_NODE':
        this.postData();
        break;
      case 'SET_TREE':
      case 'RESET_STORE':
        this.resetData();
        break;
    }
  }

  private getData(): TreeNode {
    return { ...this.data };
  }

  private setData(data): void {
    this.data = data;
  }

  private resetData(): void {
    this.data = null as CategoryTreeNode;
  }

  private onChangeRadio(displayYn) {
    if (displayYn === 'N' && this.data.isLeaf === false) {
      const accept = confirm(this.$t('PRODUCT.CATEGORIZATION.CONFIRM_UN_DISPLAY_WARN').toString());
      if (!accept) {
        this.$nextTick(() => (this.data.displayYn = 'Y'));
        return;
      }

      this.$emit('update:sub-tree', { node: this.data, updateData: { displayYn: 'N' } });
    }

    this.postData();
  }

  private postData(): void {
    if (this.data?.nodeName === '') return;
    const node = this.getData();

    if (!this.data) return;

    const uniqueKey = 'categoryNo';
    this.recordNode({ node, uniqueKey });
  }

  private onClickCopyUrl(): void {
    copy(this.data.categoryUrl);

    const copyContentName = this.data.nodeName;
    alert(this.$t('ALERT_COPY_TO_CLIPBOARD', { copyContentName }));
  }

  private onBlurTextInput() {
    if (this.validation()) this.postData();
  }
}
