





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { getAuthRadioBoxOption, SecondAuthData } from '@/const/contents/auth';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { SecondCertType } from 'ncp-api-supporter/dist/types/enum';
import SecurityAuthInput from '@/components/security/SecurityAuthInput.vue';

// TODO 마크업 적용
// TODO api 적용
// TODO 이메일, sms 의 경우 "인증번호 전송 요청" -> "인증번호 입력" -> 로그인 완료
// TODO OTP 의 경우 qr or secret 생성 -> 인증번호 입력 -> 로그인 완료
// TODO OTP 키 찾는 api 추가해야함 (요청은 했음)

@Component({ components: { SecurityAuthInput, RadioGroup, ToolTip } })
export default class AuthSecondPopup extends Vue {
  @Prop({ required: true })
  private secondAuthData: SecondAuthData;

  private secondAuthMethodRadioOption = getAuthRadioBoxOption().secondAuthMethodRadioOption;

  private authMethod = this.secondAuthData.types[0] || this.secondAuthMethodRadioOption.data[0].value;

  private authKey = '';

  private async putAuthSecondaryCert(): Promise<void> {
    const { data } = await this.$api.putAuthSecondaryCert({
      data: {
        type: this.authMethod as SecondCertType,
        certKey: this.secondAuthData.key,
        code: this.authKey,
        adminType: 'SERVICE',
        usage: 'LOGIN',
      },
    });

    if (data) {
      this.$emit('completeSecondAuth', data);
    }
  }

  private async putAdminsOtpConfig(): Promise<void> {
    const response = await this.$api.putAdminsOtpConfig({
      data: {
        code: this.authKey,
        enabled: true,
        usage: 'LOGIN',
        certKey: this.secondAuthData.key,
      },
    });

    if (response?.status === 204) this.putAuthSecondaryCert();
  }

  private openPopup(popupName: string): void {
    this.$emit('openPopup', popupName);
  }

  private secondAuth(): void {
    if (!this.authKey) {
      alert(`${this.$t('AUTH_NUMBER')}${this.$t('CHECK_INPUT_FILED')}`);
      return;
    }

    const isEnabledOtp = this.secondAuthData.target.enableOtp;

    isEnabledOtp ? this.putAuthSecondaryCert() : this.putAdminsOtpConfig();
  }

  private close(): void {
    this.$emit('closePopup');
  }
}
