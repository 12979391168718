




import { Vue, Component } from 'vue-property-decorator';
import { PostAuthTokenRequest } from 'ncp-api-supporter';
import { i18n } from '@/main';

const { VUE_APP_REDIRECT_URL, VUE_APP_NODE_ENV, VUE_APP_COMMERCE_MAIN } = process.env;

@Component
export default class OauthCodeReceiver extends Vue {
  private postTokenHandler() {
    const code = this.$route.query?.code?.toString();
    const redirectUrl = this.$route.query?.state?.toString();
    code && this.postAuthToken(code, redirectUrl);
  }

  private postAuthToken(code: string, redirectUrl?: string) {
    const request: PostAuthTokenRequest = {
      data: {
        adminType: 'SERVICE',
        grantType: 'OAUTH_CODE',
        code,
      },
    };

    if (VUE_APP_NODE_ENV === 'local') {
      request.data.redirectUrl = `${VUE_APP_REDIRECT_URL}/oauth/redirect`;
    }

    this.$api
      .postAuthToken(request)
      .then(res => {
        if (res.data.planType === 'PREMIUM') {
          alert(i18n.t('ALERT_CHECK_PLAN_TYPE', { planType: res.data.planType }));
          window.location.href = process.env.VUE_APP_PREMIUM_ADMIN_URL;

          return;
        }

        this.$router.push({
          name: 'AuthMain',
          query: { ...res.data, autoLogoutMin: res.data.autoLogoutMin.toString(), redirectUrl },
        });
      })
      .catch(e => {
        alert(e.data.message);
        window.open(VUE_APP_COMMERCE_MAIN, '_self');
      });
  }

  created() {
    this.postTokenHandler();
  }
}
